import { defineComponent } from 'vue';
import DropdownItems from '@/views/DropdownItem/DropdownItems.vue';
export default defineComponent({
    name: 'DropdownItemsLayout',
    components: { DropdownItems },
    data() {
        return {
            loading: false,
            tab: 'Dropdown Items'
        };
    }
});
