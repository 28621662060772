import { defineComponent } from 'vue';
import DropdownItemComponent from '@/components/DropdownItem/DropdownItemComponent.vue';
export default defineComponent({
    name: 'DropdownItemDialog',
    props: {
        propSelected: Array
    },
    components: { DropdownItemComponent },
    data: function () {
        return {
            show: false
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        showHideDialog() {
            this.show = !this.show;
        },
        createNew() {
            this.show = true;
        }
    }
});
